<template>
    <v-card class="courseinfoWidget">
        <iframe id="monrepos" :style="{ height: iframeHeight + 'px' }"></iframe>
    </v-card>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue'

const iframeHeight = ref(0)
let heightCheckInterval = null

const checkIframeHeight = () => {
    const iframe = document.getElementById('monrepos')
    if (iframe && iframeHeight.value === 0) {
        const appElement = iframe.contentWindow?.document?.getElementById('app')
        if (appElement) {
            iframeHeight.value = appElement.scrollHeight
        }
    } else {
        clearInterval(heightCheckInterval)
    }
}

onMounted(() => {
    const iframe = document.getElementById('monrepos')
    const iframeDoc = iframe.contentWindow.document

    const parentStyles = Array.from(document.styleSheets)
        .map((sheet) => {
            try {
                return Array.from(sheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join('\n')
            } catch (e) {
                return ''
            }
        })
        .join('\n')

    const html = `
        <!DOCTYPE html>
        <html>
            <head>
                <link rel="stylesheet" href="./gcm-vue/app.css">
                <style>
                    ${parentStyles}
                    html { overflow: hidden; }
                </style>
            </head>
            <body>
                <div id="app-monrepos"></div>
            </body>
        </html>
    `

    const adjustIframeHeight = () => {
        const appElement = iframe.contentWindow.document.getElementById('app')
        if (appElement) {
            iframeHeight.value = appElement.scrollHeight
        }
    }

    const setupObserver = () => {
        const appElement = iframe.contentWindow.document.getElementById('app')
        if (appElement) {
            const observer = new MutationObserver(() => {
                adjustIframeHeight()
            })

            observer.observe(appElement, {
                childList: true,
                subtree: true,
                characterData: true,
                attributes: true,
            })

            adjustIframeHeight()
        }
    }

    iframeDoc.open()
    iframeDoc.write(html)
    iframeDoc.close()

    const appScript = iframeDoc.createElement('script')
    appScript.src = './gcm-vue/app.js'

    appScript.onload = () => {
        const initScript = iframeDoc.createElement('script')
        initScript.textContent = 'placeInformation();'
        iframeDoc.body.appendChild(initScript)
        setupObserver()
    }

    iframeDoc.body.appendChild(appScript)
    iframe.addEventListener('load', setupObserver)

    // Height Check Interval starten
    heightCheckInterval = setInterval(checkIframeHeight, 1000)
})

// Cleanup
onUnmounted(() => {
    if (heightCheckInterval) {
        clearInterval(heightCheckInterval)
    }
})
</script>

<style lang="scss">
#monrepos {
    width: 100%;
    border: none;
}

.courseinfoWidget {
    position: relative;
}
</style>
